import React, { act, useState } from 'react'
import { useRouter } from 'next/router'
import { PaginaNode, useLoginMutation } from '@gql/graphql'
import useSocialLogin from '@hooks/useSocialLogin'
import { useLocalStorage, useTrans } from 'hooks'
import { Button, FormikField, FormLayout, WImage } from '@components/atoms'
import { Page } from 'components/organisms'
import styles from './LoginTemplate.module.sass'
import { Form, Formik } from 'formik'
import { loginValidation, registrazioneLoginValidationSchema } from '@utils/validationSchemas'
import {
  handleRescuePasswordTracking,
  handleSignUpStartTracking,
  handleUserLoginTracking,
} from '@utils/tracking'

interface Props {
  pagina?: PaginaNode
}

const LoginTemplate = (props: Props) => {
  const { pagina = undefined } = props

  const [login, { loading: loadingLogin }] = useLoginMutation()
  const [, setToken] = useLocalStorage('token', null)
  const [rememberMeEmail, setEmail] = useLocalStorage('username', null)
  const [loginError, setLoginError] = useState(false)
  const router = useRouter()
  const [, setEmailSignIn] = useLocalStorage('email_signin', null)

  const handleRecoverPassword = async () => {
    handleRescuePasswordTracking('step1_cta', 'ok')
    router.push('login/recover-password')
  }

  const handleLogin = async (values) => {
    if (!values.username || !values.password) return
    try {
      const { data } = await login({
        variables: {
          username: values.username,
          password: values.password,
        },
      })
      if (data?.tokenAuth?.token) {
        setToken(data?.tokenAuth?.token)
        setEmail(values.rememberMe ? values.username : null)
        handleUserLoginTracking()
        router.push(
          (Array.isArray(router.query?.next) ? router.query.next[0] : router.query.next) ||
            '/myaccount'
        )
      }
    } catch {
      setLoginError(true)
      handleUserLoginTracking(undefined, true)
    }
  }

  const handleRegistration = (values) => {
    setEmailSignIn(values?.username)
    handleSignUpStartTracking()
    router.push('/sign-in')
  }

  const t = useTrans()
  const tabs = [
    { key: 'accedi', label: t('Accedi') },
    { key: 'registrati', label: t('Registrati') },
  ]
  const [activeTab, setActiveTab] = useState(tabs[0].key)

  const {
    FacebookLoginButton,
    GoogleLoginButton,
    error: errorSocialLogin,
  } = useSocialLogin(true, '/myaccount', activeTab === 'registrati')

  return (
    <Page pagina={pagina} className={`${styles.root}`}>
      <main>
        <div className={styles.layout}>
          <div className={styles.imageBox}>
            <WImage src={pagina.mainImage} maxWidth={960} maxHeight={622} objectFit="cover" />
          </div>
          <div className={styles.main}>
            <div className={styles.tabs} role="tablist">
              {tabs.map((tab) => (
                <button
                  type="button"
                  onClick={() => setActiveTab(tab.key)}
                  disabled={activeTab === tab.key}
                  id={`tab-${tab.key}`}
                  role="tab"
                  aria-selected={activeTab === tab.key ? 'true' : 'false'}
                  aria-controls={`tabpanel-${tab.key}`}
                  className={styles.tab}
                >
                  {tab.label}
                </button>
              ))}
            </div>
            <div className={styles.tabPanels}>
              <div
                className={`${styles.tabPanel} ${
                  activeTab === 'accedi' ? styles['tabPanel--active'] : ''
                }`}
                id="tabpanel-accedi"
                role="tabpanel"
                aria-labelledby="tab-accedi"
              >
                <h2 className={styles.tabPanelTitle}>{t('Accedi con le tue credenziali')}</h2>

                {loginError && (
                  <p className={styles.formError}>
                    {t(errorSocialLogin) ||
                      t(
                        'Inserisci username e password corretti. In entrambi i campi le maiuscole potrebbero essere significative.'
                      )}
                  </p>
                )}
                <Formik
                  initialValues={{
                    username: rememberMeEmail ? rememberMeEmail : '',
                    password: '',
                    rememberMe: true,
                  }}
                  validationSchema={loginValidation(t)}
                  onSubmit={(values) => handleLogin(values)}
                >
                  {(formikProps) => (
                    <Form className={styles.form}>
                      <FormLayout space="sm">
                        <FormikField
                          id="login_email"
                          label={t('Indirizzo email')}
                          placeholder={t('Inserisci il tuo indirizzo email')}
                          type="email"
                          name="username"
                          formik={formikProps}
                          required
                          layout="full"
                        />
                        <FormikField
                          id="login_password"
                          label={t('Password')}
                          placeholder={t('Inserisci la tua password')}
                          type="password"
                          name="password"
                          formik={formikProps}
                          required
                          layout="full"
                        />
                      </FormLayout>
                      <div className={styles.actions}>
                        <FormikField
                          id={`login_rememberme`}
                          type="toggle"
                          checkboxLabel={t(`Ricordami`)}
                          name="rememberMe"
                          formik={formikProps}
                          layout="none"
                        />
                        <Button
                          label={t('Hai dimenticato la password?')}
                          variant="ghost"
                          size="sm"
                          onClick={handleRecoverPassword}
                          className={`${styles.forgot} ${styles['forgot--desktop']}`}
                        />
                        <Button
                          type="submit"
                          label={t('Accedi')}
                          className={styles.cta}
                          loading={loadingLogin}
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
                <div className={styles.social}>
                  <p className={styles.socialTitle}>{t('Oppure accedi con un account social')}</p>
                  <div className={styles.socialList}>
                    <FacebookLoginButton />
                    <GoogleLoginButton />
                  </div>
                </div>
              </div>
              <div
                className={`${styles.tabPanel} ${
                  activeTab === 'registrati' ? styles['tabPanel--active'] : ''
                }`}
                id="tabpanel-registrati"
                role="tabpanel"
                aria-labelledby="tab-registrati"
              >
                <h2 className={styles.tabPanelTitle}>{t('Iscriviti al nostro sito')}</h2>
                <div
                  className={styles.tabPanelDescription}
                  dangerouslySetInnerHTML={{
                    __html: t(
                      "<p>Con pochi click puoi beneficiare di numerosi vantaggi. Un esempio? L'estensione gratuita della garanzia dei tuoi prodotti!</p>"
                    ),
                  }}
                />
                <Formik
                  validationSchema={registrazioneLoginValidationSchema(t)}
                  initialValues={{
                    username: '',
                  }}
                  onSubmit={(values) => handleRegistration(values)}
                >
                  {(formikProps) => (
                    <Form className={styles.form2}>
                      <FormLayout space="sm">
                        <FormikField
                          id="registrati_email"
                          label={t('Indirizzo email')}
                          placeholder={t('Inserisci il tuo indirizzo email')}
                          type="email"
                          name="username"
                          formik={formikProps}
                          required
                          layout="full"
                        />
                      </FormLayout>
                      <Button type="submit" label={t('Registrati')} className={styles.cta} />
                    </Form>
                  )}
                </Formik>
                <div className={styles.social}>
                  <p className={styles.socialTitle}>
                    {t('Oppure registrati con un account social')}
                  </p>
                  <div className={styles.socialList}>
                    <FacebookLoginButton />
                    <GoogleLoginButton />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Page>
  )
}

export default LoginTemplate
