import React from 'react'
import { NextWrapper } from 'data-fetching-wrapper'
import { initializeApollo } from '@gql/client'
import { PaginaDocument, PaginaNode } from '@gql/graphql'
import LoginTemplate from '@components/templates/LoginTemplate/LoginTemplate'

interface Props {
  pagina?: PaginaNode
}

const Login = ({ pagina }: Props) => {
  return <LoginTemplate pagina={pagina} />
}

export const getStaticProps = NextWrapper.getStaticProps(
  async ({ preview, previewData, locale }) => {
    const client = initializeApollo(locale)

    const headers = preview && previewData?.headers ? { cookie: previewData.headers } : {}

    const {
      data: { pagina },
    } = await client.query({
      query: PaginaDocument,
      variables: { chiave: 'login', preview },
      context: {
        headers,
      },
    })

    if (!pagina) {
      return {
        notFound: true,
      }
    }

    return {
      props: { pagina },
      revalidate: 60 * 10,
    }
  },
  '/login'
)

export default Login
