import React, { useState } from 'react'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import axios from 'axios'
import { useRouter } from 'next/router'
import useTrans from './useTrans'
import useLocalStorage from './useLocalStorage'
import { useGoogleLogin } from '@react-oauth/google'
import { useSocialAuthMutation } from '@gql/graphql'
import { ButtonSocial } from '@components/atoms'
import { handleSignUpStartTracking, handleUserLoginTracking } from '@utils/tracking'

function useSocialLogin(withRedirect, url, registrazione) {
  const router = useRouter()
  const t = useTrans()
  const socialLogin = null
  const loading = null
  const [profile, setProfile] = useState({ nome: '', cognome: '', email: '' })
  const [error, setError] = useState()
  const [visibilityButtonGoogle, setVisibilityButtonGoogle] = useState(true)
  const [, setToken] = useLocalStorage('token', null)
  const [socialMutation, { loading: loadingMutation }] = useSocialAuthMutation()

  async function handleSocialLogin(provider, accessToken, profileData) {
    if (error) setError(null)
    setProfile(profileData)
    const res = await socialMutation({
      variables: {
        provider: provider,
        accessToken: accessToken,
      },
    })
      .then(async (res) => {
        setError(null)
        setToken(accessToken)
        handleUserLoginTracking(provider)
        if (withRedirect) {
          router.push(`${url}`).then((res) => router.reload())
        } else {
          router.reload()
        }
      })
      .catch((err) => {
        setError(err?.message)
        handleUserLoginTracking(provider, true)
      })
  }

  function prepareFacebookLogin(response) {
    if (response.status) {
      if (response.status !== 'unknown') setError(t('Si è verificato un errore.'))
    } else if (response.accessToken) {
      const data = {
        nome: response.first_name || '',
        cognome: response.last_name || '',
        email: response.email || '',
      }
      if (registrazione) handleSignUpStartTracking('facebook')
      handleSocialLogin('facebook', response?.accessToken, data)
    }
  }

  function prepareGoogleLogin(response) {
    if (response.error) {
      if (response.error !== 'popup_closed_by_user') setError(t('Si è verificato un errore.'))
    } else if (response.access_token) {
      axios
        .get('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: {
            Authorization: `Bearer ${response.access_token}`,
          },
        })
        .then((res) => {
          const data = {
            nome: res.data.given_name,
            cognome: res.data.family_name,
            email: res.data.email,
          }
          if (registrazione) handleSignUpStartTracking('google-oauth2')
          handleSocialLogin('google-oauth2', response.access_token, data)
        })
    }
  }

  function failureGoogleLogin(response) {
    if (response?.error === 'idpiframe_initialization_failed') {
      setVisibilityButtonGoogle(false)
    } else setError('Si è verificato un errore.')
  }

  const loginGoogle = useGoogleLogin({
    onSuccess: (tokenResponse) => prepareGoogleLogin(tokenResponse),
    onError: (credentialResponse) => failureGoogleLogin(credentialResponse),
    scope: 'profile email',
  })

  const FacebookLoginButton = () => (
    <FacebookLogin
      isMobile={false}
      appId={process.env.FACEBOOK_APP_ID}
      callback={prepareFacebookLogin}
      fields="first_name,last_name,email"
      render={(renderProps) => (
        <ButtonSocial
          variant="facebook"
          className="login__social-button"
          onClick={renderProps.onClick}
          label={registrazione ? t('Entra con Facebook') : t('Accedi con Facebook')}
        />
      )}
    />
  )

  const GoogleLoginButton = () =>
    visibilityButtonGoogle ? (
      <ButtonSocial
        variant="google"
        className="login__social-button"
        onClick={() => {
          loginGoogle()
        }}
        label={registrazione ? t('Entra con Google') : t('Accedi con Google')}
      />
    ) : null
  return { FacebookLoginButton, GoogleLoginButton, socialLogin, error, loading, profile }
}
export default useSocialLogin
